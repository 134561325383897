.slideInLeft {
  animation-name: slideInLeft;
}
.slideInRight {
  animation-name: slideInRight;
}
.slideInTop {
  animation-name: slideInTop;
}
.slideInBottom {
  animation-name: slideInBottom;
}

.slideInLeft,
.slideInRight,
.slideInTop,
.slideInBottom {
  animation-duration: 2.5s;
  animation-delay: 0.3s;
  animation-iteration-count: 1;
  //   visibility: hidden;
}

@keyframes slideInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
    visibility: hidden;
  }
  100% {
    transform: translate3d(0%, 0, 0);
    visibility: visible;
  }
}

@keyframes slideInRight {
  0% {
    transform: translate3d(100%, 0, 0);
    visibility: hidden;
  }
  100% {
    transform: translate3d(0%, 0, 0);
    visibility: visible;
  }
}
@keyframes slideInTop {
  0% {
    transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0%, 0);
    opacity: 1;
  }
}
@keyframes slideInBottom {
  0% {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0%, 0);
    opacity: 1;
  }
}
