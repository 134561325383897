html {
  font-size: 100%;
  box-sizing: border-box;
}

@font-face {
  font-family: "Stud-Regular";
  src: url(../../../stud-regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f2f5f9;
}

img {
  transition: all 0.7s ease-in-out;
}
img.a_scale:hover {
  transform: scale(1.1);
}
img::before {
  content: "";
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  z-index: 2;
  background: rgba(255, 255, 255, 0.113);
  border-radius: 100%;
  opacity: 0;
}
img:hover::before {
  animation: circle 0.9s;
}

input {
  width: 100%;
  padding: 10px 5px;
}

.container {
  padding: 0 3rem;
}

.list-nostyle {
  padding-left: 0;
  list-style: none;
}

.theme__button {
  color: white;
  background-color: var(--gold);
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 700;
  border-radius: 10px;
  padding: 1rem 2.5rem;
  border-style: none;
  transition: all 0.5s linear;
}
.theme__button.inverse {
  color: var(--gold);
  background-color: #fff;
}
.theme__button.inverse:hover {
  background-color: var(--gold);
  color: #fff;
}
.theme__button.max-width {
  width: 100%;
}
.theme__button:hover {
  background-color: var(--navy-blue);
}

.rounded-border-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}

.rounded-border-bottom {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}

.flex-items {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.flex-items > .flex-2 {
  flex: 1 0 calc(50% - 10px);
}
.flex-items > .flex-3 {
  flex: 0 0 calc(33.3333% - 10px);
}
.flex-items > .flex-4 {
  flex: 0 0 calc(25% - 10px);
}
.flex-items.column {
  flex-direction: column;
}
.flex-items.space-between {
  justify-content: space-between;
}
.flex-items.align-items-center {
  align-items: center;
  margin: auto;
  width: 100%;
}
.flex-items.justify-content-center {
  justify-content: center;
  margin: auto;
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.viewport__full-height {
  height: 100vh;
  clear: both;
}

.line-height {
  line-height: 1.5;
}

.text-align-center {
  text-align: center;
}

h1,
h2,
h3 {
  margin: 0;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

:root {
  --navy-blue: #546181;
  --gold: #bca280;
  --light-gray: #888a8c;
  --facebook: hsl(208, 92%, 53%);
}

:root {
  --font-stud: "Stud-Regular";
  --font-sert: "SantElia Rough Regular Two", sans-serif;
}

.slideInLeft {
  animation-name: slideInLeft;
}

.slideInRight {
  animation-name: slideInRight;
}

.slideInTop {
  animation-name: slideInTop;
}

.slideInBottom {
  animation-name: slideInBottom;
}

.slideInLeft,
.slideInRight,
.slideInTop,
.slideInBottom {
  animation-duration: 2.5s;
  animation-delay: 0.3s;
  animation-iteration-count: 1;
}

@keyframes slideInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
    visibility: hidden;
  }
  100% {
    transform: translate3d(0%, 0, 0);
    visibility: visible;
  }
}
@keyframes slideInRight {
  0% {
    transform: translate3d(100%, 0, 0);
    visibility: hidden;
  }
  100% {
    transform: translate3d(0%, 0, 0);
    visibility: visible;
  }
}
@keyframes slideInTop {
  0% {
    transform: translate3d(0, 100%, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0%, 0);
    opacity: 1;
  }
}
@keyframes slideInBottom {
  0% {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0%, 0);
    opacity: 1;
  }
}
.preloader {
  position: fixed;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.preloader__icon {
  animation-name: iconRotateOnYAxis;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  background-image: url(../../../images/preloader_icon.PNG);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  width: 100%;
  height: 100%;
}

@keyframes iconRotateOnYAxis {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(0, 10, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}
.header {
  position: relative;
  display: flex;
  flex-direction: column;
  background-image: url("../../../images/hero_gf_stockyards.jpg");
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.header * {
  z-index: 10;
}
.header__top {
  position: relative;
  display: block;
  background-color: var(--navy-blue);
  color: var(--gold);
  padding: 8px 0;
}
.header__top-icon {
  margin-right: 0.5rem;
  color: #fff;
}
.header__top-left-address a {
  color: var(--gold);
}
.header__top-left-address a:hover {
  color: white;
  transition: all 500ms ease;
}
.header__nav {
  background-color: white;
  padding: 1rem 0;
}
.header__nav-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__nav-menu {
  display: flex;
  gap: 2rem;
}
.header__nav-link {
  position: relative;
  font-family: var(--font-stud);
  color: var(--navy-blue);
}
.header__nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  bottom: -38px;
  transform: skew(-35deg);
  height: 4px;
  background: var(--navy-blue);
  z-index: 1;
}
.header__nav-link:hover {
  color: var(--gold);
  transition: 0.5s ease-in;
}
.header__nav-link:hover::after {
  width: 100%;
  transition: 0.5s ease-in;
}
.header__hero {
  margin-top: 4rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7rem 20rem 3rem;
}
.header__hero-title {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 5rem;
}
.header__hero-content {
  font-size: 1.5rem;
  color: #fff;
  line-height: 1.5;
}
.header__hero-button {
  color: white;
  background-color: var(--gold);
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 700;
  border-radius: 10px;
  padding: 1rem 2.5rem;
  transition: all 0.5s linear;
}
.header__hero-button:hover {
  background-color: var(--navy-blue);
}

.main__section-heading, .main__section-subheading {
  color: var(--navy-blue);
  text-align: center;
}
.main__section-heading.inverse, .main__section-subheading.inverse {
  color: var(--gold);
}
.main__section-heading {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}
.main__section-subheading {
  font-size: 1.5rem;
}

.main__feature {
  position: relative;
  display: block;
  padding: 60px 0 90px;
}
.main__feature-single {
  background-color: #fff;
  position: relative;
  overflow: hidden;
  padding: 200px 96px 40px;
  border-radius: 0.75rem;
  z-index: 1;
}
.main__feature-single-inner {
  z-index: 1;
}
.main__feature-single-inner h3 {
  font-size: 1.5rem;
}
.main__feature-single::before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  background-color: var(--navy-blue);
  width: 100%;
  height: 0;
  border-radius: 0.75rem;
  transition: height 0.5s ease;
  z-index: -1;
}
.main__feature-single-icon {
  position: absolute;
  border-radius: 50%;
  background-color: var(--navy-blue);
  width: 225px;
  height: 225px;
  top: -72px;
  left: -30px;
}
.main__feature-single-icon span {
  color: white;
  display: inline-block;
  position: relative;
  top: 108px;
  left: 86px;
  font-size: 1rem;
}
.main__feature-single:hover::before {
  height: 100%;
}
.main__feature-single:hover .main__feature-single-icon {
  background-color: var(--gold);
}
.main__feature-single:hover .main__feature-single-inner h3,
.main__feature-single:hover .main__feature-single-inner p {
  color: #fff;
}

.main__feature-title {
  font-size: 1.5rem;
}

.main__about {
  padding-top: 7rem;
  position: relative;
  padding-bottom: 3rem;
  background-color: var(--navy-blue);
  color: #fff;
}
.main__about-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}
.main__about-content::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../../../images/gf_logo_xl-gold.PNG);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.2;
  z-index: -1;
}
.main__about-team-single {
  width: 375px;
  border-radius: 0.625rem;
  padding: 2rem;
}
.main__about-team-single:hover .main__about-team-single-image::before {
  transform: perspective(400px) rotateX(0deg) scaleY(1);
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.7s;
  transition-property: all;
}
.main__about-team-single:hover .main__about-team-single-image img {
  transform: scale(1.1);
}
.main__about-team-single-image {
  position: relative;
  border-radius: 0.625rem;
  display: block;
  overflow: hidden;
  z-index: 1;
}
.main__about-team-single-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(188, 162, 128, 0.3);
  transform: perspective(400px) rotateX(90deg) scaleY(0.5);
  transform-origin: center;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-property: all;
  z-index: 1;
}
.main__about-team-single-image img {
  transition: all 0.5s ease-in;
  border-radius: 0.625rem;
  width: 100%;
  object-fit: contain;
}
.main__about-team-single-content {
  text-align: center;
}
.main__about-team-single-content h3 {
  color: var(--gold);
}
.main__about-topiclist {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.main__about .bull {
  background-image: url("../../../images/gf_icon_blue.PNG");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  height: 34px;
  padding-top: 7px;
  padding-left: 44px;
}
.main__about-image {
  max-width: 100%;
  padding: 5rem;
}
.main__about-image > img {
  width: 100%;
  border-radius: 0.625rem;
}

.main__services {
  padding-top: 7rem;
}

.main__services-single {
  display: block;
  margin-bottom: 30px;
  background-color: #fff;
  position: relative;
  border-radius: 0.625rem;
}
.main__services-single::before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  bottom: 0;
  background-color: var(--navy-blue);
  width: 100%;
  height: 0;
  border-radius: 0.625rem;
  transition: height 0.5s ease;
  z-index: -1;
}
.main__services-single-image {
  display: block;
  border-radius: 0.625rem;
  overflow: hidden;
  padding: 0.625rem 0.625rem 0 0.625rem;
  z-index: 1;
}
.main__services-single-image img {
  max-width: 100%;
}
.main__services-single-content {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 35px 30px;
  box-sizing: border-box;
}
.main__services-single:hover {
  background: none;
}
.main__services-single:hover::before {
  height: 100%;
}
.main__services-single:hover .main__services-single-content p,
.main__services-single:hover .main__services-single-content h3 {
  color: #fff;
}
.main__services-single:hover .main__services-single-content a {
  background-color: var(--navy-blue);
}

.main__contact {
  padding-top: 7rem;
}
.main__contact-left {
  position: relative;
  padding: 5rem;
}
.main__contact-left-contact {
  position: relative;
}
.main__contact-left-image {
  top: 0;
  right: -170px;
}
.main__contact img {
  width: 100%;
  border-radius: 0.625rem;
}
.main__contact-form {
  padding: 20px 30px;
  width: 100%;
}
.main__contact-form textarea {
  min-width: 100%;
}

.main__footer {
  background-color: var(--navy-blue);
  display: block;
  padding: 80px 0 50px;
  color: #fff;
  line-height: 1.5rem;
  text-align: center;
  margin-top: 2rem;
}
.main__footer-links-group {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.footer_fb {
  color: #fff;
  transition: all 0.5s ease-in;
}
.footer_fb:hover {
  color: var(--gold);
}

.footer__nav-item {
  color: var(--gold);
  font-weight: 700;
  padding: 0.25rem 1rem;
  border-radius: 0.75rem;
  transition: all 0.5s ease-in;
}
.footer__nav-item:hover {
  background-color: var(--gold);
  color: #fff;
}

.main__footer-bottom {
  padding-top: 2rem;
}
.main__footer-bottom hr {
  background-color: var(--gold);
  height: 0.2rem;
  border: none;
}

.copyright {
  font-weight: 600;
}