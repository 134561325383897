// Preloader

.preloader {
  position: fixed;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &__icon {
    animation-name: iconRotateOnYAxis;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    background-image: url(../../../images/preloader_icon.PNG);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto;
    width: 100%;
    height: 100%;
  }
}

@keyframes iconRotateOnYAxis {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(0, 10, -5deg);
  }
  100% {
    transform: perspective(400px);
  }
}
