// Main - Home Page

// Typography
.main__section {
  &-heading,
  &-subheading {
    // font-family: var(--font-stud);
    color: var(--navy-blue);
    text-align: center;
    &.inverse {
      color: var(--gold);
    }
  }
  &-heading {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  &-subheading {
    font-size: 1.5rem;
  }
}

// Feature
.main__feature {
  position: relative;
  display: block;
  padding: 60px 0 90px;

  &-single {
    background-color: #fff;
    position: relative;
    overflow: hidden;
    padding: 200px 96px 40px;
    border-radius: 0.75rem;
    z-index: 1;
    &-inner {
      z-index: 1;
      & h3 {
        font-size: 1.5rem;
      }
    }
    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      background-color: var(--navy-blue);
      width: 100%;
      height: 0;
      border-radius: 0.75rem;
      transition: height 0.5s ease;
      z-index: -1;
    }
    &-icon {
      position: absolute;
      border-radius: 50%;
      background-color: var(--navy-blue);
      width: 225px;
      height: 225px;
      top: -72px;
      left: -30px;
      & span {
        color: white;
        display: inline-block;
        position: relative;
        top: 108px;
        left: 86px;
        font-size: 1rem;
      }
    }

    &:hover {
      &::before {
        height: 100%;
      }

      .main__feature-single {
        &-icon {
          background-color: var(--gold);
        }
        &-inner {
          & h3,
          p {
            color: #fff;
          }
        }
      }
    }
  }
}

.main__feature-title {
  font-size: 1.5rem;
}

// About Us
.main__about {
  padding-top: 7rem;
  position: relative;
  padding-bottom: 3rem;
  background-color: var(--navy-blue);
  color: #fff;

  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(../../../images/gf_logo_xl-gold.PNG);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 0.2;
      z-index: -1;
    }
  }

  &-team-single {
    // height: 500px;
    width: 375px;
    border-radius: 0.625rem;
    padding: 2rem;

    &:hover {
      .main__about-team-single-image::before {
        transform: perspective(400px) rotateX(0deg) scaleY(1);
        transition-delay: 0.1s;
        transition-timing-function: ease-in-out;
        transition-duration: 0.7s;
        transition-property: all;
      }
      .main__about-team-single-image img {
        transform: scale(1.1);
      }
    }
    &-image {
      position: relative;
      border-radius: 0.625rem;
      display: block;
      overflow: hidden;
      z-index: 1;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(188, 162, 128, 0.3);
        transform: perspective(400px) rotateX(90deg) scaleY(0.5);
        transform-origin: center;
        transition-delay: 0.1s;
        transition-timing-function: ease-in-out;
        transition-duration: 0.5s;
        transition-property: all;
        z-index: 1;
      }
      & img {
        transition: all 0.5s ease-in;
        border-radius: 0.625rem;
        width: 100%;
        object-fit: contain;
      }
    }
    &-content {
      text-align: center;
      & h3 {
        color: var(--gold);
      }
      & p {
      }
    }
  }

  &-topiclist {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .bull {
    background-image: url("../../../images/gf_icon_blue.PNG");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
    height: 34px;
    padding-top: 7px;
    padding-left: 44px;
  }

  &-image {
    max-width: 100%;
    padding: 5rem;
    & > img {
      width: 100%;
      border-radius: 0.625rem;
    }
  }
}

// Services
.main__services {
  padding-top: 7rem;
}
.main__services-single {
  display: block;
  margin-bottom: 30px;
  background-color: #fff;
  position: relative;
  border-radius: 0.625rem;
  &::before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    bottom: 0;
    background-color: var(--navy-blue);
    width: 100%;
    height: 0;
    border-radius: 0.625rem;
    transition: height 0.5s ease;
    z-index: -1;
  }

  &-image {
    display: block;
    border-radius: 0.625rem;
    overflow: hidden;
    padding: 0.625rem 0.625rem 0 0.625rem;
    z-index: 1;
    & img {
      max-width: 100%;
    }
  }

  &-content {
    // background-color: #fff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 35px 30px;
    box-sizing: border-box;
  }

  &:hover {
    background: none;
    &::before {
      height: 100%;
    }

    .main__services-single {
      &-content {
        & p,
        h3 {
          color: #fff;
        }
        & a {
          background-color: var(--navy-blue);
        }
      }
    }
  }
}

// Contact Us
.main__contact {
  padding-top: 7rem;
  &-left {
    position: relative;
    padding: 5rem;
    &-contact {
      position: relative;
    }
    &-image {
      // position: absolute;
      top: 0;
      right: -170px;
    }
  }
  & img {
    width: 100%;
    border-radius: 0.625rem;
  }

  &-form {
    padding: 20px 30px;
    width: 100%;

    & textarea {
      min-width: 100%;
    }
  }
}
