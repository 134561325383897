// Header

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  background-image: url("../../../images/hero_gf_stockyards.jpg");
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  & * {
    z-index: 10;
  }

  &__top {
    position: relative;
    display: block;
    background-color: var(--navy-blue);
    color: var(--gold);
    padding: 8px 0;
    &-icon {
      margin-right: 0.5rem;
      color: #fff;
    }
    &-left-address {
      & a {
        color: var(--gold);

        &:hover {
          color: white;
          transition: all 500ms ease;
        }
      }
    }
  }

  &__nav {
    background-color: white;
    padding: 1rem 0;

    &-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-menu {
      display: flex;
      gap: 2rem;
    }
  }

  &__nav-link {
    position: relative;
    font-family: var(--font-stud);
    color: var(--navy-blue);
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 0;
      // transition: all 0.5s cubic-bezier(0.35, 0.7 0.35 1);
      bottom: -38px;
      transform: skew(-35deg);
      height: 4px;
      background: var(--navy-blue);
      z-index: 1;
    }
    &:hover {
      color: var(--gold);
      transition: 0.5s ease-in;
      &::after {
        width: 100%;
        transition: 0.5s ease-in;
      }
    }
  }
  &__hero {
    margin-top: 4rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // padding-bottom: 3rem;
    padding: 7rem 20rem 3rem;

    &-title {
      color: white;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 5rem;
    }

    &-content {
      font-size: 1.5rem;
      color: #fff;
      line-height: 1.5;
    }

    &-button {
      color: white;
      background-color: var(--gold);
      display: inline-block;
      font-size: 1.5rem;
      font-weight: 700;
      border-radius: 10px;
      padding: 1rem 2.5rem;
      transition: all 0.5s linear;

      &:hover {
        background-color: var(--navy-blue);
      }
    }
  }
}
