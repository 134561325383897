.main__footer {
  background-color: var(--navy-blue);
  display: block;
  padding: 80px 0 50px;
  color: #fff;
  line-height: 1.5rem;
  text-align: center;
  margin-top: 2rem;

  &-links-group {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
}
.footer_fb {
  color: #fff;
  transition: all 0.5s ease-in;
  &:hover {
    color: var(--gold);
  }
}
.footer__nav-item {
  color: var(--gold);
  font-weight: 700;
  padding: 0.25rem 1rem;
  border-radius: 0.75rem;
  transition: all 0.5s ease-in;
  &:hover {
    background-color: var(--gold);
    color: #fff;
  }
}

.main__footer-bottom {
  padding-top: 2rem;

  & hr {
    background-color: var(--gold);
    height: 0.2rem;
    border: none;
  }
}

.copyright {
  font-weight: 600;
}
