html {
  font-size: 100%;
  box-sizing: border-box;
}

@font-face {
  font-family: "Stud-Regular";
  src: url(../../../stud-regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f2f5f9;
}

img {
  // transform: scale(0.8);
  transition: all 0.7s ease-in-out;
  &.a_scale:hover {
    transform: scale(1.1);
  }
  &::before {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    z-index: 2;
    background: rgba(255, 255, 255, 0.113);
    border-radius: 100%;
    opacity: 0;
  }
  &:hover::before {
    animation: circle 0.9s;
  }
}

input {
  width: 100%;
  padding: 10px 5px;
}
.container {
  padding: 0 3rem;
}

.list-nostyle {
  padding-left: 0;
  list-style: none;
}

.theme__button {
  color: white;
  background-color: var(--gold);
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 700;
  border-radius: 10px;
  padding: 1rem 2.5rem;
  border-style: none;
  transition: all 0.5s linear;

  &.inverse {
    color: var(--gold);
    background-color: #fff;

    &:hover {
      background-color: var(--gold);
      color: #fff;
    }
  }

  &.max-width {
    width: 100%;
  }

  &:hover {
    background-color: var(--navy-blue);
  }
}

.rounded-border-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}

.rounded-border-bottom {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}

// Flex box controls

.flex-items {
  display: flex;
  flex-wrap: wrap;
  // width: 100%;
  gap: 0.5rem;

  & > .flex-2 {
    flex: 1 0 calc(50% - 10px);
  }

  & > .flex-3 {
    flex: 0 0 calc(33.3333% - 10px);
  }

  & > .flex-4 {
    flex: 0 0 calc(25% - 10px);
  }

  &.column {
    flex-direction: column;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.align-items-center {
    align-items: center;
    margin: auto;
    width: 100%;
  }

  &.justify-content-center {
    justify-content: center;
    margin: auto;
    width: 100%;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.viewport__full-height {
  height: 100vh;
  clear: both;
}

.line-height {
  line-height: 1.5;
}

.text-align-center {
  text-align: center;
}
